.App {
  text-align: center;
  overflow-y: auto;
  background-image: linear-gradient(to bottom right, rgba(21, 241, 219, 0.3), rgb(25, 195, 210));
  font-family: "Calibri";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-form {
  background-color: white;
}
/* Table TBody Tr Td{
  padding-top: 8px!important;
  padding-bottom: 8px!important;
} */

@media print 
{
  #skureport{
    font-size: small;
    
  }
  #skureport TBody Tr Td{
    padding-top: 3px;
    padding-bottom: 3px
    }
  #skureport Tr:nth-of-type(12n){
    page-break-after: always;
  }
  
}